import React, { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ThemeContext } from './context/ThemeContext';
import { useAuth } from './context/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Register from './pages/Register';
import ConfirmSignUp from './pages/ConfirmSignUp';
import MfaSetup from './pages/MfaSetup';
import AccountManagement from './pages/AccountManagement';
import EndpointManagement from './pages/EndpointManagement';
import AddEndpoint from './pages/AddEndpoint';
import Dashboard from './pages/Dashboard';
import UserProfile from './pages/UserProfile';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

function AppContent() {
  const { darkMode } = useContext(ThemeContext);
  const { isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`flex flex-col min-h-screen ${darkMode ? 'dark' : ''}`}>
      <Header />
      <main className="flex-grow bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirm-signup" element={<ConfirmSignUp />} />
          <Route path="/mfa-setup" element={<MfaSetup />} />
          <Route path="/account" element={<ProtectedRoute><AccountManagement /></ProtectedRoute>} />
          <Route path="/manage-endpoints" element={<ProtectedRoute><EndpointManagement /></ProtectedRoute>} />
          <Route path="/add-endpoint" element={<ProtectedRoute><AddEndpoint /></ProtectedRoute>} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default AppContent;