import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, confirmSignIn } from '@aws-amplify/auth';
import { useAuth } from '../context/AuthContext'; // Adjust the import path as needed

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [signInData, setSignInData] = useState(null);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const { nextStep, signInResult } = await signIn({
        username: formData.username,
        password: formData.password,
      });

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD') {
        setShowPasswordChange(true);
        setSignInData({ nextStep, signInResult });
      } else {
        setIsAuthenticated(true);
        navigate('/');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError(err.message || 'An error occurred during login.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (formData.newPassword !== formData.confirmNewPassword) {
      setError('New passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const { isSignedIn } = await confirmSignIn(
        signInData.signInResult,
        {
          challengeResponse: formData.newPassword,
        },
        signInData.nextStep.signInStep
      );

      if (isSignedIn) {
        setIsAuthenticated(true);
        navigate('/');
      } else {
        setError('Failed to change password. Please try again.');
      }
    } catch (err) {
      console.error('Password change error:', err);
      setError(err.message || 'An error occurred while changing the password.');
    } finally {
      setLoading(false);
    }
  };
  if (showPasswordChange) {
    return (
      <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
        <h1 className="text-4xl font-bold mb-8 text-gray-900 dark:text-white">Change Password</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handlePasswordChange} className="max-w-md mx-auto">
          <div className="mb-4">
            <label htmlFor="newPassword" className="block mb-2 text-gray-800 dark:text-gray-200">New Password</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="confirmNewPassword" className="block mb-2 text-gray-800 dark:text-gray-200">Confirm New Password</label>
            <input
              type="password"
              id="confirmNewPassword"
              name="confirmNewPassword"
              value={formData.confirmNewPassword}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
              required
            />
          </div>
          <button 
            type="submit" 
            className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
            disabled={loading}
          >
            {loading ? 'Changing Password...' : 'Change Password'}
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-gray-900 dark:text-white">Login</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="max-w-md mx-auto">
        <div className="mb-4">
          <label htmlFor="username" className="block mb-2 text-gray-800 dark:text-gray-200">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block mb-2 text-gray-800 dark:text-gray-200">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
          disabled={loading}
        >
          {loading ? 'Logging in...' : 'Log In'}
        </button>
      </form>
    </div>
  );
};

export default Login;