import React, { useState, useEffect } from 'react';
import { getCurrentUser, updateUserAttributes, fetchUserAttributes } from '@aws-amplify/auth';

const UserProfile = () => {
  const [userAttributes, setUserAttributes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchAttributes();
  }, []);

  async function fetchAttributes() {
    try {
      const user = await getCurrentUser();
      const attributes = await fetchUserAttributes();
      setUserAttributes(attributes);
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch user attributes');
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    setUserAttributes({ ...userAttributes, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setLoading(true);

    try {
      await updateUserAttributes(userAttributes);
      setSuccessMessage('Profile updated successfully');
    } catch (error) {
      setError('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="container mx-auto px-6 py-12 bg-gray-100 dark:bg-gray-900">
      <h1 className="text-4xl font-bold mb-8 text-gray-900 dark:text-white">User Profile</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
      <form onSubmit={handleSubmit} className="max-w-md mx-auto">
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 text-gray-800 dark:text-gray-200">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={userAttributes.email || ''}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="block mb-2 text-gray-800 dark:text-gray-200">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={userAttributes.name || ''}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phone_number" className="block mb-2 text-gray-800 dark:text-gray-200">Phone Number</label>
          <input
            type="tel"
            id="phone_number"
            name="phone_number"
            value={userAttributes.phone_number || ''}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update Profile'}
        </button>
      </form>
    </div>
  );
};

export default UserProfile;