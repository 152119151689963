import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import { useAuth } from '../context/AuthContext'; // Adjust this import path if needed
import DarkModeSwitch from './DarkModeSwitch';
import logo from '../assets/logo.png'; // Make sure this path is correct

const Header = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsAuthenticated(false);
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <header className="bg-blue-600 dark:bg-blue-800 text-white">
      <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="Observant Logo" className="h-8 w-auto mr-2" />
            <span className="font-bold text-xl">Observant</span>
          </Link>
        </div>
        <div className="flex items-center">
          <Link to="/features" className="mx-2">Features</Link>
          <Link to="/pricing" className="mx-2">Pricing</Link>
          <Link to="/about" className="mx-2">About</Link>
          <Link to="/contact" className="mx-2">Contact</Link>
          {isAuthenticated ? (
            <>
              <Link to="/dashboard" className="mx-2">Dashboard</Link>
              <Link to="/profile" className="mx-2">Profile</Link>
              <button onClick={handleSignOut} className="mx-2 bg-red-500 text-white px-4 py-2 rounded">Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className="mx-2 bg-white text-blue-600 px-4 py-2 rounded">Login</Link>
              <Link to="/register" className="mx-2 bg-green-500 text-white px-4 py-2 rounded">Register</Link>
            </>
          )}
          <DarkModeSwitch />
        </div>
      </nav>
    </header>
  );
};

export default Header;